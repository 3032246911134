.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}
.rdw-editor-main {
  border: 1px solid #efefef !important;
  border-radius: 4px !important;
  min-height: 130px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.dataTables_filter {
  float: right;
}

.dataTables_paginate .pagination {
  float: right;
}

.rdw-editor-wrapper {
  min-height: 180px !important;
}

.ReactModal__Overlay {
  z-index: 10001 !important;
}

.react-datepicker-wrapper {
  .form-control {
    width: 405% !important;
  }
}

.dropzone {
  h3 {
    text-align: center !important;
    padding-top: 100px !important;
  }
  div {
    min-height: 200px !important;
  }
  min-height: 200px !important;
}

input[type="radio"] + label {
  cursor: pointer !important;
}

.topnav .navbar-nav .nav-item .nav-link.active {
  color: #ffffff !important;
}

.navbar-header {
  box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2);
  background: white;
}

.navbar-brand-box {
  background: 'white';
}

.icon .iconify .fa{
  background-color: transparent;
}

.pac-container {
  background-color: #FFF;
  z-index: 2001;
  position: fixed;
  display: inline-block;
  float: left;
}

.modal {
  z-index: 2000;
}

.modal-backdrop {
  z-index: 1000;
}


.page-full {  
  height: 100%;
  margin: 0;
}

.content-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.map-container{
  position: relative;
  width: 100%;  
  height: 80vh;
}


.gmaps {
  position: fixed !important;
  height: 100vh !important;
  width: 100% !important;
  left: 0;
}

// .gmnoprint {
//   display: none !important;
// }

.cursor-pointer{
  cursor: pointer;
}

.of-y-scroll {
  overflow-y: scroll;
}

.mh-400 {
  max-height: 400px;
}
.float-device-button {
  position: absolute;
  top: 60px;
  left: 10px;
  z-index: 99999;
  max-width: 300px;
}