.card_ships_holder {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  gap: 16px;
  /* margin: 16px 0; */
  border-radius: 8px;
  padding: 4px;
  border: 2px solid silver;
}

.card_ships_holder:hover,
.card_ships_holder:focus {
  border: 2px solid var(--primary);
}

.card_ships {
  display: inline-flex;
  position: relative;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
  gap: 16px;
  margin: 4px;
  height: 40px;
  padding: 12px 24px;
  /* margin: auto; */
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  border: 2px solid silver;
  background: transparent;
  transition: all ease-out .15s;
}

.select_indicator {
  display: inline-flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 8px;
  left: 8px;
  top: auto;
  bottom: 2px;
  border-radius: 4px;
  border: 2px solid var(--primary);
  width: calc(100% - 16px);
  height: 2px;
}

.card_ships i {
  display: none;
}

.profile_image {
  width: 36px;
  min-width: 36px;
  height: auto;
  display: inline-flex;
}

.selected {
  border: 2px solid silver;
  background: silver;
  color: #333;
}

.selected i {
  display: inline-flex;
}