// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

//RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

@import "./icons.scss";

// Theme Custom Scss
@import "./custom.scss";
